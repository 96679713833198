<template>
  <div class="content">
    <TitleInfo title1="组态管理" title2="场景管理"></TitleInfo>
    <div class="main">
      <div class="title flexCB">
        <div class="key align-center">
          <div>场景名称：</div>
          <el-input
            v-model="queryForm.name"
            placeholder="请输入场景名称"
            class="keyInput"
          />
          <div>场景编码：</div>
          <el-input
            v-model="queryForm.code"
            placeholder="请输入场景编码"
            class="keyInput"
          />
          <el-button
            :icon="Search"
            color="#11488B"
            :round="false"
            @click="initGetList"
            >搜索</el-button
          >
          <el-button
            :icon="Refresh"
            color="#67C23A"
            :round="false"
            @click="resetInfo"
            >重置</el-button
          >
          <el-button
            v-if="user.CanAdd"
            :icon="Plus"
            type="warning"
            :round="false"
            @click="handleDialogValue()"
            >新增</el-button
          >
        </div>
      </div>
      <div class="tableInfo">
        <el-table
          :data="tableData"
          border
          class="tableInfo"
          style="width: 100%; height: 100%"
        >
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column prop="Name" label="场景名称"></el-table-column>
          <el-table-column prop="Code" label="场景编码"></el-table-column>
          <el-table-column prop="TypeLabel" label="场景类型"></el-table-column>
          <el-table-column
            prop="DeviceTypeName"
            label="关联设备类型"
          ></el-table-column>
          <el-table-column prop="Creator" label="创建人"></el-table-column>
          <el-table-column
            prop="LastModifiedBy"
            label="最后编辑人"
          ></el-table-column>
          <el-table-column
            prop="CreateTime"
            label="最新修改时间"
          ></el-table-column>
          <el-table-column prop="action" label="操作" width="500">
            <template #default="{ row }">
              <el-button
                v-if="user.CanEdit"
                size="small"
                type="warning"
                :icon="Edit"
                @click="handleDialogValue(row)"
                >编辑</el-button
              >
              <el-button
                v-if="user.CanDelete"
                size="small"
                type="danger"
                :icon="Delete"
                @click="del(row)"
                >删除</el-button
              >
              <el-button
                v-if="user.CanEdit"
                size="small"
                type="primary"
                :icon="MostlyCloudy"
                @click="openGraphPage(row.Code)"
                >设计</el-button
              >
              <el-button
                size="small"
                color="#626aef"
                :icon="Promotion"
                @click="openPreviewPage(row.Type, row.DeviceType, row.Code)"
                >预览</el-button
              >
              <el-button
                v-if="user.CanAdd"
                size="small"
                type="success"
                :icon="CopyDocument"
                @click="copyScenes(row)"
                >克隆</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom align-center">
        <el-pagination
          v-model:current-page="queryForm.pageIndex"
          v-model:page-size="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          popper-class="dropdownPopper"
        />
      </div>
    </div>
    <Dialog
      v-model="dialogVisible"
      :dialogTitle="dialogTitle"
      :dialogRowValue="rowInfo"
      v-if="dialogVisible"
      :copyID="copyID"
      @initList="initGetList"
    ></Dialog>
    <DeviceListDialog
      v-model="deviceListDialogInfo.visible"
      :deviceTypeID="deviceListDialogInfo.deviceTypeID"
      :code="deviceListDialogInfo.code"
      @setDeviceID="SetDeviceID"
      v-if="deviceListDialogInfo.visible"
    ></DeviceListDialog>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import {
  Search,
  Refresh,
  Plus,
  Edit,
  Delete,
  CopyDocument,
  Promotion,
  MostlyCloudy
} from '@element-plus/icons-vue'
import { isNull } from '@/utitls/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import TitleInfo from '@/components/TitleInfo'
import { GetScenesPageList, DeleteScenes } from '@/api/Scenes'
import Dialog from './components/dialog.vue'
import router from '@/router'
import { useStore } from 'vuex'
import DeviceListDialog from './components/deviceListDialog.vue'
const queryForm = ref({
  name: '',
  code: '',
  pageIndex: 1,
  pageSize: 10
})
const total = ref(0)
const tableData = reactive([])
const dialogVisible = ref(false)
const deviceListDialogInfo = ref({
  visible: false,
  deviceTypeID: '',
  code: ''
})
const dialogTitle = ref('')
const rowInfo = ref({})
const copyID = ref('')
const user = ref({})
onMounted(() => {
  user.value = useStore().state.login.user
  initGetList()
})
const initGetList = async () => {
  GetScenesPageList(queryForm.value)
    .then((res) => {
      if (res) {
        total.value = res.Total
        tableData.length = 0
        res.Data.forEach((element) => {
          tableData.push(element)
        })
      } else {
        total.value = 0
        tableData.length = 0
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
      console.log(err)
      total.value = 0
      tableData.length = 0
    })
}
const handleSizeChange = (pageSize) => {
  queryForm.value.pageIndex = 1
  initGetList()
}
const handleCurrentChange = (pageNum) => {
  initGetList()
}
const handleDialogValue = (row) => {
  if (isNull(row)) {
    dialogTitle.value = '添加组态场景'
    rowInfo.value = {}
  } else {
    dialogTitle.value = '修改组态场景信息'
    rowInfo.value = JSON.parse(JSON.stringify(row))
  }
  dialogVisible.value = true
}
const del = (row) => {
  ElMessageBox.confirm('是否确认删除当前组态场景?', '提醒', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      DeleteScenes({ ID: row.ID })
        .then((res) => {
          if (res) {
            ElMessage({
              showClose: true,
              type: 'success',
              message: '删除成功!'
            })
            initGetList()
          }
        })
        .catch((err) => {
          ElMessage({
            showClose: true,
            type: 'error',
            message: err,
            duration: 2000
          })
        })
    })
    .catch(() => {})
}
const copyScenes = (row) => {
  dialogTitle.value = '克隆组态场景'
  rowInfo.value = {}
  copyID.value = row.ID
  dialogVisible.value = true
}
const resetInfo = () => {
  queryForm.value = {
    name: '',
    code: '',
    pageIndex: 1,
    pageSize: 10
  }
  initGetList()
}
const openGraphPage = (code) => {
  const { href } = router.resolve({
    name: 'GraphDesign',
    query: { code: code }
  })
  window.open(href, '_blank')
}
const openPreviewPage = (type, deviceTypeID, code) => {
  if (type == 1) {
    console.log(code)
    deviceListDialogInfo.value.code = code
    deviceListDialogInfo.value.deviceTypeID = deviceTypeID
    deviceListDialogInfo.value.visible = true
  } else {
    const { href } = router.resolve({
      name: 'GraphPreview',
      query: { code: code }
    })
    window.open(href, '_blank')
  }
}
const SetDeviceID = (deviceID, code) => {
  const { href } = router.resolve({
    name: 'GraphPreview',
    query: { code: code, deviceID: deviceID, type: 1 }
  })
  window.open(href, '_blank')
}
</script>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<style lang="scss" scoped src="./assets/index.scss"></style>
