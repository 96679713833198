<template>
  <el-dialog
    :model-value="dialogVisible"
    title="选择数据源"
    width="500px"
    @opened="handleOpen"
    @close="handleClose"
    :close-on-click-modal="false"
    draggable
  >
    <el-form :model="form" label-width="70px" ref="formRef">
      <el-form-item label="设备" prop="deviceID">
        <el-select
          v-model="selectDevice"
          placeholder="请选择设备"
          style="width: 100%"
          value-key="id"
          filterable
        >
          <el-option
            v-for="item in DeviceList"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { GetDeviceFillListByTypeID } from '@/api/Device'
import { ElMessage } from 'element-plus'
const props = defineProps({
  deviceTypeID: {
    type: String,
    defaule: '',
    required: true
  },
  code: {
    type: String,
    defaule: '',
    required: true
  }
})
var DeviceList = reactive([])
var selectDevice = ref({
  id: '',
  name: ''
})

const formRef = ref(null)
const form = ref({})
const dialogVisible = ref(false)

const emits = defineEmits(['update:modelValue', 'setDeviceID'])
const handleOpen = () => {
  InitList()
}
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  if (selectDevice.value.id == '') {
    ElMessage({
      message: '设备不能为空',
      type: 'error'
    })
    return
  }
  formRef.value.validate(async (valid, fields) => {
    if (valid) {
      emits('setDeviceID', selectDevice.value.id, props.code)
      handleClose()
    } else {
      console.log('error submit!', fields)
    }
  })
}

const InitList = () => {
  GetDeviceFillListByTypeID({ deviceTypeID: props.deviceTypeID })
    .then((res) => {
      DeviceList.length = 0
      res.Data.forEach((item) => {
        DeviceList.push({
          id: item.ID,
          name: item.Name
        })
      })
    })
    .catch((err) => {
      console.log(err)
    })
}
</script>

<style></style>
