<template>
  <el-dialog
    :model-value="dialogVisible"
    :title="dialogTitle"
    width="500px"
    @opened="handleOpen"
    @close="handleClose"
    draggable
  >
    <el-form :model="form" label-width="85px" ref="formRef" :rules="rules">
      <el-form-item label="场景名称" prop="Name">
        <el-input v-model="form.Name" />
      </el-form-item>
      <el-form-item label="场景编码" prop="Code">
        <el-input v-model="form.Code" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="场景类型" prop="Type">
        <el-select v-model="form.Type" style="width: 100%">
          <el-option
            v-for="item in SceneTypeList"
            :key="item.TypeValue"
            :label="item.TypeDesc"
            :value="item.TypeValue"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备类型" prop="DeviceTypeID" v-if="form.Type == 1">
        <el-select
          v-model="form.DeviceType"
          placeholder="Select"
          style="width: 100%"
        >
          <el-option
            v-for="item in DeviceTypeList"
            :key="item.ID"
            :label="item.Name"
            :value="item.ID"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { AddScenes, EditScenes, CopyScenes } from '@/api/Scenes'
import { GetSceneTypes } from '@/api/EnumInfo'
import { GetDeviceTypeFillList } from '@/api/DeviceType'
import { ElMessage } from 'element-plus'
import { isNull } from '@/utitls/validate'
const props = defineProps({
  dialogTitle: {
    type: String,
    default: '',
    required: true
  },
  dialogRowValue: {
    type: Object,
    default: () => {}
  },
  copyID: {
    type: String,
    default: ''
  }
})
const formRef = ref(null)
var form = ref({})
const dialogVisible = ref(false)
const rules = reactive({
  Name: [{ required: true, message: '场景名称不能为空', trigger: 'blur' }],
  Code: [{ required: true, message: '场景编码不能为空', trigger: 'blur' }]
})
const SceneUseList = ref([])
const SceneTypeList = ref([])
const DeviceTypeList = ref([])
const emits = defineEmits(['update:modelValue', 'initList'])
const handleOpen = () => {
  GetSceneTypes().then((res) => {
    res.Data.forEach((item) => {
      SceneTypeList.value.push(item)
    })
  })
  GetDeviceTypeFillList().then((res) => {
    res.Data.forEach((item) => {
      DeviceTypeList.value.push(item)
    })
  })
  if (props.dialogTitle === '修改组态场景信息') {
    form.value = props.dialogRowValue
  }
}

const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  formRef.value.validate(async (valid, fields) => {
    if (valid) {
      if (props.dialogTitle == '添加组态场景') {
        AddScenes(form.value)
          .then((res) => {
            ElMessage({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
      } else if (props.dialogTitle === '克隆组态场景') {
        CopyScenes({
          Name: form.value.Name,
          Code: form.value.Code,
          copyID: props.copyID
        })
          .then((res) => {
            ElMessage({
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
      } else
        EditScenes(form.value)
          .then((res) => {
            ElMessage({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
    } else {
      console.log('error submit!', fields)
    }
  })
}
</script>
