import $http from '@/utitls/request'

/**
 * 获取点位类型枚举
 */
export const GetNodeTypes = () => {
    return $http.get('/api/EnumInfo/GetNodeTypes')
}
/**
 * 获取动效规则类型枚举
 */
export const GetResponseRuleTypes = (data) => {
    return $http.get('/api/EnumInfo/GetResponseRuleTypes', { params: data })
}
/**
 * 获取场景类型枚举
 */
export const GetSceneTypes = () => {
    return $http.get('/api/EnumInfo/GetSceneTypes')
}
/**
 * 获取场景用途枚举
 */
export const GetSceneUses = () => {
    return $http.get('/api/EnumInfo/GetSceneUses')
}